import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import UpdatesList from "../components/updates-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query UpdatesPageQuery($skip: Int!, $limit: Int!) {
    updatesPage: sanityUpdatesLanding {
      title
      description
      featuredImage {
        ...SanityImage
        alt
      }
    }

    updates: allSanityUpdate(
      sort: { fields: [publicationDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          publicationDate
          slug {
            current
          }
          _rawExcerpt
          featuredImage {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const UpdatesPageTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const updatesPage = (data || {}).updatesPage;
  const updates = (data || {}).updates.edges;

  return (
    <Layout>
      <Seo
        title={updatesPage.title}
        description={updatesPage.description}
        image={updatesPage.featuredImage}
      />

      {updatesPage.title && (<PageHeader title={updatesPage.title} />)}
      {updates && (<UpdatesList posts={updates} currentPage={currentPage} numPages={numPages} />)}
    </Layout>
  );
};

export default UpdatesPageTemplate;
